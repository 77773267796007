/* eslint-disable arrow-body-style */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import tw, { css } from "twin.macro";
import { useWindowDimensions } from "~hooks";
import * as T from "~styles/Typography.jsx";
import Grid, { GRID_PADDING_PX } from "~styles/Grid.jsx";
import { Button, Go, LineSwipe, InfoCard } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Wave1BWhite } from "~assets/svg/header-1-shape-b-white.svg";

const TextCardSection = ({
  data: {
    backgroundColor,
    backgroundColor2,
    headingFontColor,
    fontColor,
    subheading,
    heading,
    body,
    button,
    withWave,
    cardRows
  }
}) => {
  const [containerCSS, setContainerCSS] = useState(null);
  const { windowSize } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!windowSize?.width) {
      return;
    }

    let widthDiff = 0;

    if (windowSize.width > 1800) {
      widthDiff = parseInt((windowSize.width - 1800) / 2);
    }

    const width = `calc(
      100% + ${widthDiff}px +
        ${GRID_PADDING_PX}px
    );`;

    setContainerCSS(width);
  }, [windowSize]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      id={subheading?.replace(/\s+/g, `-`).toLowerCase()}
      css={[
        css`
          background-color: ${backgroundColor?.hex || `#f5f6fa`};
        `,
        tw`w-full relative overflow-hidden`
      ]}
    >
      <Grid>
        {/* text content */}
        <div
          css={[
            tw`col-span-12 md:col-span-4 lg:col-span-5 relative pt-12 md:pt-24 lg:pt-32 pb-4 md:pb-24 md:pr-8 lg:pr-16`
          ]}
        >
          <header
            css={[
              css`
                color: ${headingFontColor?.hex || `#002762`};
              `,
              tw`relative flex items-center col-span-4 md:pr-8 lg:pr-0 mb-10 md:mb-12 lg:mb-12`
            ]}
          >
            <T.Head font="4">{subheading}</T.Head>

            <div css={[tw`w-32 relative block ml-5`]}>
              <LineSwipe
                color={headingFontColor?.hex === `#ffffff` && `#ffffff`}
                delay={500}
                duration={2000}
              />
            </div>
          </header>

          {heading && (
            <div
              css={[
                css`
                  color: ${headingFontColor?.hex || `#002762`};
                `,
                tw`mb-12 font-bold`
              ]}
            >
              <T.Head font="2" level="3">
                {heading}
              </T.Head>
            </div>
          )}

          <T.Body
            _css={[
              css`
                color: ${fontColor?.hex || `#000000`};
              `,
              tw`whitespace-pre-wrap`
            ]}
            font="1"
          >
            {body}
          </T.Body>

          {button && (
            <div css={[tw`w-full relative mt-12`]}>
              <Go to={button?.url}>
                <Button
                  _css={[
                    css`
                      width: 14rem;
                      ${MEDIA_QUERIES.large} {
                        width: 16rem;
                      }
                    `,
                    tw`h-14 relative block border`
                  ]}
                  text={button?.text}
                />
              </Go>
            </div>
          )}
        </div>

        {/* cards */}
        <div
          css={[
            css`
              //
            `,
            tw`col-span-12 md:col-span-8 lg:col-span-7 lg:col-start-6 h-full relative`
          ]}
        >
          <div
            css={[
              css`
                width: calc(100% + 4rem);
                margin-left: -2rem;
                padding-bottom: 4rem;

                ${MEDIA_QUERIES.desktop} {
                  width: ${containerCSS};
                  margin-left: auto;
                  padding-bottom: ${cardRows?.length > 1 ? `4rem` : `0`};
                }
              `,
              tw`h-full relative flex flex-col md:items-start items-center justify-center pt-12 md:pt-24 lg:pt-32 px-8 lg:px-10 xl:px-12`
            ]}
          >
            {/* background */}
            <div
              css={[
                css`
                  background-color: ${backgroundColor2?.hex || `#f5f6fa`};
                  z-index: 0;
                `,
                tw`w-full h-full absolute top-0 bottom-0 left-0 `
              ]}
            >
              {withWave && (
                <div
                  css={[
                    css`
                      overflow: hidden;
                      transform: rotate(180deg);
                    `,
                    tw`w-full absolute z-0 bottom-0`
                  ]}
                >
                  <Wave1BWhite
                    css={[
                      css`
                        opacity: 0.2;
                      `
                    ]}
                  />
                </div>
              )}
            </div>

            {cardRows?.map((cardRow) => (
              <>
                {cardRow?.heading && (
                  <div
                    css={[
                      css`
                        color: ${headingFontColor?.hex || `#002762`};
                        z-index: 1;
                      `,
                      tw`mb-8 font-bold`
                    ]}
                  >
                    <T.Head
                      _css={[tw`font-bold mt-4`]}
                      font="b1"
                      level="3"
                      fontKeyOverride={windowSize?.width > 1024 ? `h6` : `h4`}
                    >
                      {cardRow?.heading}
                    </T.Head>
                  </div>
                )}

                <ul
                  css={[
                    tw`relative md:flex space-y-6 md:space-y-0 md:space-x-4 mb-8`
                  ]}
                >
                  {cardRow?.cards?.map((card) => {
                    const key = `info-card-${card.heading}`;

                    return (
                      <li
                        key={key}
                        css={[
                          css`
                            ${MEDIA_QUERIES.desktop} {
                              flex: 50% 1;
                            }
                          `
                        ]}
                      >
                        <InfoCard
                          _css={[tw`h-full`]}
                          backgroundColor={card.backgroundColor}
                          headingFontColor={card.headingFontColor}
                          fontColor={card.fontColor}
                          heading={card.heading}
                          subsections={card.subsections}
                        />
                      </li>
                    );
                  })}
                </ul>
              </>
            ))}
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default TextCardSection;

/** ============================================================================
 * graphql
 */
export const query = graphql`
  fragment TextCardSectionFragment on SanityTextCardSection {
    _type

    backgroundColor {
      hex
      title
    }

    backgroundColor2 {
      hex
      title
    }

    headingFontColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }

    subheading
    heading
    body
    button {
      text
      url
    }

    withWave

    cardRows {
      heading
      cards {
        backgroundColor {
          hex
          title
        }

        headingFontColor {
          hex
          title
        }

        fontColor {
          hex
          title
        }

        heading

        subsections {
          _type
          body
          heading
        }
      }
    }
  }
`;
